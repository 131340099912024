import {AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild} from "@angular/core";
import {DTOs} from "@mrbeany/stacks_shared/lib/dto.module";
import ContactInformation = DTOs.ContactInformation;
import {FormControl, FormGroup, UntypedFormGroup, Validators} from "@angular/forms";
import {tap} from "rxjs/operators";
import LocationInformation = DTOs.LocationInformation;

// todo: use nominatim to geocode the location and create an image from that location that should be used instead of the embed
@Component({
  selector: "app-contact",
  templateUrl: "./contact.component.html",
  styleUrls: ["./contact.component.scss"],
})
export class ContactComponent implements OnInit, AfterViewInit {
  @ViewChild('mapIframe') mapIframe!: ElementRef<HTMLIFrameElement>;

  @Input() contact!: ContactInformation;
  contactForm: FormGroup = new FormGroup({});
  showLocationForm = false;
  showPhone = false;
  showEmail = false;
  editing = false;

  constructor() {}

  ngOnInit() {
    this.contactForm = new FormGroup({
      location: new FormGroup({
        name: new FormControl(this.contact.location?.name || ""),
        address1: new FormControl(this.contact.location?.address1 || ""),
        address2: new FormControl(this.contact.location?.address2 || ""),
        province: new FormControl(this.contact.location?.province || ""),
        city: new FormControl(this.contact.location?.city || ""),
        state: new FormControl(this.contact.location?.state || ""),
        postalCode: new FormControl(this.contact.location?.postalCode || ""),
        country: new FormControl(this.contact.location?.country || ""),
      }),
      phone: new FormControl(this.contact.phone || ""),
      email: new FormControl(this.contact.email || ""),
    });
    this.contactForm.valueChanges
      .pipe(
        tap((res) => {
          this.contact.phone = res?.phone;
          this.contact.email = res?.email;
          this.contact.location = res?.location;
          console.log(this.contact);
        })
      )
      .subscribe();
  }

  ngAfterViewInit() {
    console.log(this.mapIframe.nativeElement);
    this.mapIframe.nativeElement.addEventListener('click', this.onIframeClick);
  }
  onIframeClick() {
    console.log('got click');
    // Navigate to a new page
    window.open(
      'https://maps.google.com/maps/dir/?api=1&destination='
      + (this.contact.location?.address1 || '') + ','
      + (this.contact.location?.address2 || '') + ','
      + (this.contact.location?.province || '') + ','
      + (this.contact.location?.city || '') + ','
      + (this.contact.location?.state || '') + ','
      + (this.contact.location?.postalCode || '') + ','
      + (this.contact.location?.country || '')
      , '_blank');  // Replace with your desired URL
 }

  deleteLocationForm() {
    this.showLocationForm = false;
    this.contactForm?.get("location")?.reset();
  }

  addLocationForm() {
    this.showLocationForm = true;
  }

  addPhone() {
    this.showPhone = true;
  }

  deletePhone() {
    this.showPhone = false;
    this.contactForm?.get("phone")?.reset();
  }
  addEmail() {
    this.showEmail = true;
  }

  deleteEmail() {
    this.showEmail = false;
    this.contactForm?.get("email")?.reset();
  }

  registerClick() {
    console.log("click");
  }
}
